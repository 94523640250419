.builderview {
  display: flex;
}

.builderview .buildercomponents {
  flex: 0.17;
  background-color: #dddddd;
  margin: 0 10px 0 0;
  padding: 0 10px 10px 10px;
  border-radius: 5px;
}

.builderview .smallcolumn {
  flex: 0.1;
}

.builderview .builderresult {
  flex: 1;
}

.buildercomponents button {
  display: block;
  margin: 5px 0 5px 0;
  border: none;
  padding: 7px;
  background-color: #5890c3;
  color: #f4f3f0;
  border-radius: 3px;
  width: 130px;
  cursor: pointer;
}

.confirmbutton {
  display: inline;
  width: unset;
  margin: 20px 20px 0 0;
}

.builderinput {
  width: 98%;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 4px;
}

input[type="checkbox"] {
  width: 20px;
  margin: 0;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
}

.optioncomponent {
  margin-bottom: 20px;
}

.ptop {
  padding-top: 10px;
}

.labeltoitems {
  margin-bottom: 7px;
}

.timersize {
  font-weight: bold;
  font-size: 2.4em;
}

.sliderwidth {
  width: 400px;
}

.buildercomponents h2 {
  font-size: 1.8em;
}

.buildercomponents h4 {
  font-size: 1.1em;
}

.buildercomponents .savebutton {
  background-color: #5fa450;
}

.buildercomponents .cancelbutton {
  background-color: #bababa;
  color: black;
}

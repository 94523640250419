.spanbutton {
  background-color: #2563eb;
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.chatausgabe {
  height: 60vh;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #d8d6bb;
  overflow: auto;
}

.meintext {
  float: right;
  color: black;
  background-color: #c3ecac;
  padding: 10px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
  clear: both;
  margin-left: 20%;
  text-align: right;
  line-height: 0.92em;
}

.systemnachricht {
  margin: 0 auto 10px auto;
  background-color: #8fcbf3;
  padding: 2px 2px 10px 2px;
  border-radius: 5px;
  clear: both;
  text-align: center;
  line-height: 0.92em;
  position: relative;
  top: 10px;
  display: block;
  font-size: 0.8em;
  color: black;
}

.anderertext {
  float: left;
  color: black;
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-right: 20%;
  margin-bottom: 5px;
  border-radius: 10px;
  clear: both;
  margin-left: 20px;
  line-height: 0.92em;
}

.textbox {
  border: 1px solid #4d7feb;
  border-radius: 3px;
  padding: 8px;
  width: calc(100% - 200px);
}

.smalltime {
  color: #999999;
  text-align: right;
  margin-bottom: 0;
  font-size: 0.6em;
}

.chatarea {
  padding: 5px 50px 0 0;
}

.chatarea > p {
  margin-top: 20px;
}

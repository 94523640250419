.header-menu {
  background-color: #5890c3;
  padding: 0 20px;
  height: 100px;
  color: white;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.5em;
  position: relative;
  cursor: pointer;
  top: 3px;
}

.rightMargin {
  margin-right: 20px;
}

.leftMargin {
  margin-left: 20px;
}

[dir="rtl"] .leftMargin {
  margin-right: 20px;
  margin-left: 0;
}

.bigger {
  font-size: 1.5em;
}

.rightflex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.anchorButton {
  border: none;
  background-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 1em;
}

@media screen and (max-width: 800px) {
  .notonmobile {
    display: none;
  }
}

.language_dropdown {
  position: fixed;
  right: 10px;
  top: 50px;
  background-color: white;
  margin-right: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  z-index: 2;
}

[dir="rtl"] .language_dropdown {
  right: auto;
  left: 10px;
  top: 50px;
  margin-right: 0px;
  margin-left: 20px;
}

.language_dropdown p {
  margin: 5px 10px 5px 10px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.language_dropdown p span {
  margin-right: 10px;
}

[dir="rtl"] .language_dropdown p span {
  margin-right: 0px;
  margin-left: 10px;
}

.language_dropdown p:hover {
  background-color: lightgray;
}

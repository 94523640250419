.verticalMenu {
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
}

.verticalMenu ul {
  list-style-type: none;
}

.verticalMenu li {
  margin-bottom: 10px;
  color: #4d7feb;
  border: 1px solid #4d7feb;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
}

.presName {
  text-align: start;
  color: #4d7feb;
  margin-left: 25px;
  margin-bottom: 20px;
  width: 110%;
}

.presName input {
  border: none;
  background: none;
  border-radius: 5px;
  margin-right: 5px;
  flex: 1;
  width: 70%;
}

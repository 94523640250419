.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.messagebox {
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  border-radius: 10px;
  color: #5890c3;
  padding: 30px;
  max-width: 800px;
  z-index: 11;
}

.messagebox button {
  margin-right: 10px;
}

.msgtext {
  margin-bottom: 10px;
}

.messagebox input,
.messagebox select {
  background-color: white;
  border: 1px solid #5890c3;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  top: -15px;
  display: block;
}

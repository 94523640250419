.spiced {
  align-items: center;
}

.spiced-active {
  background-color: #eeeeee;
}

.spiced:focus {
  outline: none;
}

.spiced span {
  background-color: #eeeeee;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 0 6px 0 6px;
  font-weight: bold;
  box-shadow: 2px 2px 5px 0px #aaaaaa;
  display: inline-block;
}

.spiced span.unknown {
  background-color: red;
  color: white;
}

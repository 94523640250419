.gaugedetail {
  text-align: center;
  display: flex;
}

.gaugedetail .canvasdiv {
  flex: 0.5;
}

.gaugedetail .feedbackdiv {
  flex: 1.5;
}

.gaugecanvas {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #dddddd;
}

.preslist {
  color: #4d7feb;
}

.centercontainer {
  position: absolute;
  left: 50%;
  top: 120px;
  transform: translate(-50%, 0%);
  padding: 0 10px 20px 10px;
  width: 50%;
}

.centercontainer > span {
  float: right;
}

[dir="rtl"] .centercontainer > span {
  float: left;
}

.prescriptionlist {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.prescriptionlist li {
  margin-bottom: 10px;
  margin-right: 20px;
  background-color: #4d7feb;
  color: white;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  flex: 1;
}

.prescriptionlist li.disabledbutton {
  margin-bottom: 10px;
  background-color: lightgray;
  color: white;
  border-radius: 5px;
  padding: 5px;
  cursor: default;
  text-align: center;
  flex: 0.4;
}

.disabledicon {
  color: lightgray;
}

.disabledicon svg {
  cursor: default;
}

.prescriptionbox {
  margin-left: 20px;
  position: relative;
  width: 500px;
  color: #4d7feb;
}

svg {
  cursor: pointer;
}

td {
  padding: 10px;
  border-bottom: 1px solid #4d7feb;
}

th {
  text-align: left;
}

[dir="rtl"] th {
  text-align: right;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.user_dropdown {
  position: fixed;
  background-color: white;
  margin-right: 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.user_dropdown p {
  margin: 5px 10px 5px 10px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.user_dropdown p:hover {
  background-color: lightgray;
}

.online {
  color: lightgreen;
}

.offline {
  color: lightgray;
}

.busy {
  color: red;
}

.smallicon {
  font-size: 0.7em;
  position: relative;
  top: -6px;
}

.filterselect {
  padding: 3px;
  width: 20px;
  background-color: white;
}

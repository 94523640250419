.center {
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  border-radius: 10px;
  color: black;
  padding: 0 10px 20px 10px;
}

.labelInput {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  align-items: center;
  margin-bottom: 15px;
}

.labelInput > input {
  margin-left: 20px;
  height: 30px;
  width: 300px;
  border-radius: 5px;
}

[dir="rtl"] .labelInput > input {
  margin-left: 0px;
  margin-right: 20px;
}

.error {
  border: 1px solid red;
  background-color: #f8d7da;
  padding: 5px 10px;
  color: red;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media screen and (max-width: 990px) {
  .labelInput {
    flex-wrap: wrap;
  }
  .labelInput > input {
    margin-left: 0px;
  }
}

.smaller {
  font-size: 0.8em;
  font-weight: normal;
}

.success {
  border: 1px solid green;
  background-color: #d4edda;
  padding: 5px 10px;
  color: green;
  border-radius: 5px;
  margin-bottom: 10px;
}

.googleloginltr,
.googleloginrtl {
  margin: 0 auto;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 7px 0 5px 0;
  font-size: 1.3em;
}

.googleloginltr img {
  display: inline-block;
  margin-right: 10px;
  margin-left: -30px;
}

.googleloginrtl img {
  display: inline-block;
  margin-right: -30px;
  margin-left: 10px;
}

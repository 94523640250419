.mycentercontainer {
  color: #4d7feb;
  position: relative;
  left: 0%;
  top: 60px;
  padding: 0 10px 20px 10px;
  flex: 0 1 0;
  min-width: 40vw;
}

.mycentercontainer > span {
  float: right;
}

.mytable {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

td {
  padding: 10px;
  border-bottom: 1px solid #4d7feb;
}

th {
  text-align: left;
}

.columnflex {
  display: flex;
  flex-direction: row;
}

.highlighted {
  background-color: rgba(255, 144, 0, 0.6);
}

.mytable tr {
  cursor: pointer;
}

.reviewbox {
  background-color: #4d7feb;
  padding: 10px;
  margin: 10px 0 10px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
}

.stepselect {
  padding: 3px;
  width: 10%;
  background-color: white;
  border: 1px solid #4d7feb;
  color: #4d7feb;
  border-radius: 5px;
  width: 100%;
  display: block;
}

.content {
  background-color: #d3d3d3;
}

.drawer {
  position: fixed;
  top: 50%;
}

.leftsidedrawer {
  left: 0;
}

.rightsidedrawer {
  right: 0;
}

.ptop {
  padding-top: 30px;
}

.dialog {
  margin: 0 auto;
  background-color: white;
  border-radius: 15px;
  text-align: center;
}

/* .closebutton {
  display: none;
  background-color: #5890c3;
  color: white;
  font-size: 1.2em;
} */

.dialogtitle {
  background-color: #5890c3;
  padding: 20px 0 30px 0;
  color: white;
  margin: 0;
  border-radius: 15px;
}

.backbutton {
  display: block;
  float: left;
  margin-left: 20px;
  background-color: #5890c3;
  color: white;
  font-size: 1.2em;
}

@media screen and (min-width: 800px) {
  .dialog {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    max-width: 600px;
    padding: 0 0 20px;
    border: 1px solid black;
  }
}

.closebutton {
  display: block;
  float: right;
  margin-right: 20px;
  background-color: #5890c3;
}

.optionbuttons {
  margin-right: 10px;
}

.highlightedOption {
  border: 5px solid orange;
}

.editableInput {
  width: 90%;
  background-color: #f8f8f8;
}

.itemol {
  list-style-type: decimal;
}

.itemol li {
  margin-left: 30px;
}

.gaugeflex {
  display: flex;
}

.gaugeflex .gaugefeedbackitem {
  flex: 1.5;
}

.gaugeflex .gaugecanvasitem {
  flex: 0.5;
}
